import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <section className='honbun'>
      <p>{`【補足】`}</p>
      <p>{`質問で、`}</p>
      <p>{`「図では、中域のピアノは『左右対称』に描かれていますが、どのようにパンを操作したらよいですか？ピアノのステレオトラックは、パンをLかRかにしか振れないです」`}</p>
      <p>{`「同様に、図ではシンセやクラッシュが楕円状に描かれていますが、これもどのように操作したらよいですか？」`}</p>
      <p>{`というのがあったので、回答します。`}</p>
      <p>{`前提として、この図は「こうしてください」という「指示」ではなく、「こんな感じです」という「イメージ図」として作っています。`}<br parentName="p"></br>{`
`}{`その時に、ピアノやシンセやクラッシュのように「ステレオで左右に広がった音」を「楕円」として表現しましたが、中域は楽器が多く、楕円にすると重なりまくって見にくくなるので、ピアノに関してはやむなく左右に分けて「広がり」を表現しています。`}</p>
      <p>{`ですので、「どう操作するのか？」という質問に対しては、「図は、各楽器の『ステレオの広がり表現』を加味した『ただの模式図』なので、これを作ろうとする必要はありません。特別な操作は必要なく、通常通りパンを使います」という答えになります。`}<br parentName="p"></br>{`
`}{`この時、このような「ステレオの広がり」を持ったトラックは、パンを操作すると「広がった状態」のまま左右に動きます。もし、「左右の広がりの間隔」を変えたいのであれば、スタジオワンに付属の「Dual Pan」というプラグインを使えばできます（使い方は、スタジオワンの説明書を見てください）。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      